<template>
  <moe-page title="新增单品折扣">
    <moe-card class="mb-20" :autoHeight="true">
      <template slot="header">
        <moe-steps :active="active" :stepsList="[{ title: '基本信息设置' }, { title: '设置商品优惠' }, { title: '完成折扣活动' }]"></moe-steps>
      </template>

      <template v-if="active === 0">
        <moe-form
          ref="goodsDiscountForm"
          :showBack="false"
          :model="goodsDiscountParams"
          :rules="rules"
          :defaultVerify="false">
          <el-form-item label="活动标签" prop="tag">
            <el-input v-model.trim="goodsDiscountParams.tag" placeholder="请输入活动标签" clearable maxlength="5"></el-input>
          </el-form-item>

          <el-form-item label="活动名称" prop="name">
            <el-input v-model.trim="goodsDiscountParams.name" placeholder="请输入活动名称" clearable maxlength="16"></el-input>
            <div class="color-info font-10">活动名称仅用于商家自己管理活动，不会显示给用户</div>
          </el-form-item>

          <el-form-item label="优惠方式" prop="method">
            <moe-radio-group :disabled="IS_EDIT" v-model="goodsDiscountParams.method" :radioBoxList="$moe_data.discountMethod"></moe-radio-group>
            <div class="color-info font-10 df aic">
              <moe-icon name="reminder" size="20"></moe-icon>
              <div class="ml-5">活动创建后，优惠方式将无法修改</div>
            </div>
          </el-form-item>

          <el-form-item label="优惠级别" prop="level">
            <moe-radio-group :disabled="IS_EDIT" v-model="goodsDiscountParams.level" :radioBoxList="$moe_data.presaleLevel"></moe-radio-group>
            <div class="color-info font-10 df aic">
              <moe-icon name="reminder" size="20"></moe-icon>
              <div class="ml-5">活动创建后，优惠级别将无法修改</div>
            </div>
          </el-form-item>

          <el-form-item label="活动时间" prop="startTime">
            <el-date-picker
              style="width: 100%;"
              placement="bottom-start"
              v-model="datetime"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="活动时间开始时间"
              end-placeholder="活动时间结束时间"
              @change="(datetime) => ([goodsDiscountParams.startTime, goodsDiscountParams.endTime] = datetime || ['', ''])" />
          </el-form-item>

          <template slot="tool">
            <el-button v-if="IS_EDIT" type="primary" icon="el-icon-right" :loading="goodsDiscountLoad" @click="handleStep(1, 'updateDiscount')">下一步</el-button>
            <el-button v-else type="primary" icon="el-icon-thumb" :loading="goodsDiscountLoad" @click="addDiscount()">{{ goodsDiscountLoad ? '保存中' : '保存并继续' }}</el-button>
          </template>
        </moe-form>
      </template>

      <template v-if="active > 0">
        <moe-describe-list title="" :col="2">
          <moe-describe-item label="活动标签"> {{ goodsDiscountParams.tag }} </moe-describe-item>
          <moe-describe-item label="活动名称"> {{ goodsDiscountParams.name }} </moe-describe-item>
          <moe-describe-item label="优惠方式"> {{ $moe_format.getDiscountMethod(goodsDiscountParams.method) }} </moe-describe-item>
          <moe-describe-item label="优惠级别"> {{ $moe_format.getPresaleLevel(goodsDiscountParams.level) }} </moe-describe-item>
          <moe-describe-item label="活动时间">{{ goodsDiscountParams.startTime }} 至 {{ goodsDiscountParams.endTime }} </moe-describe-item>
          <moe-describe-item label="创建时间"> {{ createTime }} </moe-describe-item>
        </moe-describe-list>

        <template v-if="active === 3">
          <div class="df aifs ml-40 mb-40">
            <el-button
              style="font-size: 26px;"
              icon="el-icon-circle-check"
              type="text">
              "商品折扣活动"设置成功！
            </el-button>
          </div>
          <div class="df aifs ml-40">
            <el-button type="primary" icon="el-icon-back" :loading="goodsDiscountLoad" @click="$moe_coordinator.navigateBack()">返回列表</el-button>
          </div>
        </template>
      </template>

      <template v-if="active === 1">
        <div class="ml-30 font-20 fwb">设置商品优惠</div>

        <div style="height: 700px;min-height: 700px;" class="df fdc">
          <moe-form
            ref="goodsDiscountGoodsForm"
            class="auto-form"
            :showBack="false"
            :model="form"
            :rules="rules"
            :defaultVerify="false">
            <div class="df1 df fdc">
              <moe-table
                :stripe="true"
                :key="itemKey"
                :numberShow="false"
                :data="form.configList"
                :mode="false"
                :showPage="false"
                :params="{ pageNum: 1 }"
                emptyText="请添加活动商品"
                rowKey="goodsId">
                <!-- 操作按钮 -->
                <template slot="tool">
                  <el-form-item prop="configList" :rules="rules.configList">
                    <el-button type="primary" icon="el-icon-circle-plus-outline" @click="form.showDialog = true;">添加商品</el-button>
                  </el-form-item>
                </template>

                <el-table-column label="商品信息" min-width="200">
                  <template slot-scope="{ row }">
                    <div class="df aic">
                      <moe-image :src="row.coverUrl" width="80px" height="80px" />
                      <div style="text-align:left" class="ml-10">
                        <p class="fwb">{{ row.name }}</p>
                        <p class="font-12 color-info">商品编号: {{ row.goodsId }}</p>
                      </div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="单价" min-width="200">
                  <template slot-scope="{ row }">
                    {{ `¥ ${row.minPrice}` }}{{ $moe_math.mathGreaterThan(row.maxPrice, row.minPrice) ? ` - ¥ ${row.maxPrice}` : '' }}
                  </template>
                </el-table-column>

                <el-table-column label="优惠方式" min-width="200" v-if="goodsDiscountParams.level === 'GOOD'">
                  <div slot-scope="{ row, $index }" class="df aic jcc mt-20">
                    <el-form-item :prop="`configList.${$index}.discount`" :rules="rules.discount">

                      <div class="df aic" v-if="goodsDiscountParams.method === 'DISCOUNT'">
                        <div>打</div>
                        <el-input-number class="ml-5 w-100" step-strictly v-model="row.discount" controls-position="right" @blur="() => handleBlur($index)" :precision="1" :step="0.1" :min="3.0" :max="9.9"></el-input-number>
                        <div class="ml-5 mr-5">折</div>
                        <el-tooltip content="请输入3.0~9.9之间的值，最多保留一位小数" placement="top">
                          <moe-icon name="reminder" size="16px"></moe-icon>
                        </el-tooltip>
                      </div>

                      <div class="df aic" v-else-if="goodsDiscountParams.method === 'REDUCE'">
                        <div>减</div>
                        <el-input class="ml-5 mr-5 w-150" placeholder="请输入" :value="row.discount" @input="(value) => changeConfigListDiscount(value, $index)"></el-input>
                        <div>元</div>
                      </div>

                    </el-form-item>
                  </div>
                </el-table-column>

                <el-table-column label="优惠方式" min-width="200" v-if="goodsDiscountParams.level === 'SKU'">
                  <div slot-scope="{ row, $index }" class="df aic jcc mt-20">
                    <el-form-item :prop="`configList.${$index}.minDiscount`" :rules="rules.minDiscount">
                      <div class="df fdc w-100">
                        <div>{{ $moe_format.getDiscountMethod(goodsDiscountParams.method) }}</div>
                        <el-button
                          v-if="$moe_math.mathGreaterThan(row.minDiscount, 0) && $moe_math.mathGreaterThan(row.minDiscount, 0)"
                          @click="queryShopGoodsById(row, $index)"
                          icon="el-icon-circle-check"
                          type="text">
                          优惠设置
                        </el-button>
                        <el-button
                          v-else
                          @click="queryShopGoodsById(row, $index)"
                          icon="el-icon-thumb"
                          type="text">
                          优惠设置
                        </el-button>
                      </div>
                    </el-form-item>
                  </div>
                </el-table-column>

                <el-table-column label="优惠后价格" min-width="200">
                  <template slot-scope="{ row }">
                    {{ discountComputed(row) }}
                  </template>
                </el-table-column>

                <el-table-column label="操作" width="200" fixed="right">
                  <template slot-scope="{ row, $index }">
                    <el-button icon="el-icon-delete" size="small" type="danger" @click="handleDelete(row, $index)">撤出活动</el-button>
                  </template>
                </el-table-column>
              </moe-table>
            </div>

            <template slot="tool">
              <el-button type="primary" plain icon="el-icon-back" :loading="goodsDiscountLoad" @click="handleStep(0)">{{ goodsDiscountLoad ? '保存中' : '上一步' }}</el-button>
              <el-button type="primary" icon="el-icon-upload2" :loading="goodsDiscountLoad" @click="handleStep(3, 'updateDiscountConfigList')">{{ goodsDiscountLoad ? '保存中' : '提交' }}</el-button>
            </template>
           </moe-form>
        </div>
      </template>
    </moe-card>

    <goodsDialog
      :showActivityRepel="true"
      :showDialog.sync="form.showDialog"
      :defaultSelectIds="form.configList && form.configList.length ? form.configList.map(({ goodsId }) => goodsId) : []"
      @close="handleClose">
    </goodsDialog>

    <moe-dialog :show="goodsListSpecForm.showDialog" title="SKU优惠设置" width="70%" @close="handleDialogClose()">
      <div class="dialog_content">
        <moe-form
          ref="goodsItemForm"
          class="default-form"
          :showBack="false"
          :showTool="false"
          :model="goodsListSpecForm"
          :rules="rules">
          <moe-table
            :key="itemKey"
            url=""
            :maxHeight="480"
            :data="goodsListSpecForm.list"
            :number-show="false"
            :mode="false"
            :params="{ pageNum: 1 }">
            <el-table-column label="商品编号" prop="goodsId" width="100" />

            <el-table-column label="SKU" min-width="200">
              <template slot-scope="{ row }">
                <div class="df aic jcc">
                  <moe-tag class="mr-5" v-for="(item, index) in row.specValueStr.split(',')" :key="index">{{ item }}</moe-tag>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="库存" prop="stock" width="100" />

            <el-table-column label="单价" min-width="200">
              <template slot-scope="{ row }">
                {{ `¥ ${row.salePrice}` }}
              </template>
            </el-table-column>

            <el-table-column label="优惠方式" width="200">
              <div slot-scope="{ row, $index }" class="df aic jcc mt-20">
                <el-form-item :prop="`list.${$index}.discount`" :rules="rules.discount">
                  <div class="df aic" v-if="goodsDiscountParams.method === 'DISCOUNT'">
                    <div>打</div>
                    <el-input-number class="ml-5 w-100" step-strictly v-model="row.discount" controls-position="right" @blur="() => handleBlur($index)" :precision="1" :step="0.1" :min="3.0" :max="10.0"></el-input-number>
                    <div class="ml-5 mr-5">折</div>
                    <el-tooltip content="请输入3.0~10.0之间的值，最多保留一位小数" placement="top">
                      <moe-icon name="reminder" size="16px"></moe-icon>
                    </el-tooltip>
                  </div>

                  <div class="df aic" v-else-if="goodsDiscountParams.method === 'REDUCE'">
                    <div>减</div>
                    <el-input class="ml-5 mr-5 w-150" placeholder="请输入" :value="row.discount" @input="(value) => changeConfigListDiscount(value, $index)"></el-input>
                    <div>元</div>
                  </div>
                </el-form-item>
              </div>
            </el-table-column>

            <el-table-column label="优惠后价格" min-width="200" :formatter="skuDiscountComputed" />
          </moe-table>
        </moe-form>
      </div>
      <template slot="footer">
        <el-button @click="handleDialogClose()">取消</el-button>
        <el-button type="primary" @click="handleConfirm()">确定</el-button>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
import goodsDialog from '@/views/ActivityManage/components/goodsDialog.vue';
export default {
  name: 'ActivityManageGoodsDiscountAdd',
  components: {
    goodsDialog
  },
  data() {
    const checkDiscount = (rule, value, callback) => {
      if (!value) {
        callback(new Error(`请输入减免金额`));
      } else {
        const { level, method } = this.goodsDiscountParams;
        if (level === 'GOOD') {
          let index = rule.fullField.split('.')[1];
          let findItem = this.form.configList[index];
           let discount70 = this.$moe_math.mathDiv(Math.ceil(this.$moe_math.mathMul(this.$moe_math.mathMul(findItem.minPrice, 0.7), 100)), 100);

          if (method === 'REDUCE' && this.$moe_math.mathLessThanOrEqualTo(findItem.discount, 0)) {
            return callback(new Error(`减免金额必须大于0元`));
          } else if (method === 'REDUCE' && this.$moe_math.mathGreaterThan(findItem.discount, discount70)) {
            return callback(new Error(`减免金额不能超过满足金额的70%`));
          } else {
            callback();
          }
        } else if (level === 'SKU') {
          let index = rule.fullField.split('.')[1];
          let findItem = this.goodsListSpecForm.list[index];
          let discount70 = this.$moe_math.mathDiv(Math.ceil(this.$moe_math.mathMul(this.$moe_math.mathMul(findItem.salePrice, 0.7), 100)), 100);

          // if (method === 'REDUCE' && this.$moe_math.mathLessThanOrEqualTo(findItem.discount, 0)) {
          //   return callback(new Error(`减免金额必须大于0元`));
          // } else
          if (method === 'REDUCE' && this.$moe_math.mathGreaterThan(findItem.discount, discount70)) {
            return callback(new Error(`减免金额不能超过满足金额的70%`));
          } else {
            callback();
          }
        }
      }
    }
    const checkMinDiscount = (rule, value, callback) => {
      if (!value) {
        callback(new Error(`请设置优惠方式`));
      } else {
        if (this.goodsDiscountParams.level === 'SKU' && this.$moe_math.mathLessThanOrEqualTo(value, 0)) {
          callback(new Error(`请设置优惠方式`));
        } else {
          callback();
        }
      }
    }
    const verify = this.$moe_verify.verifyForm
    const rules = {
      tag: verify.isExtent(['请输入活动标签', '长度为 4 ~ 5位'], '4~5'),
      name: verify.isExtent(['请输入活动名称', '长度为 2 ~ 16位'], '2~16'),
      method: verify.isEmpty('请选择优惠方式'),
      level: verify.isEmpty('请选择优惠级别'),
      startTime: verify.isEmpty('请选择活动时间'),
      configList: verify.isEmpty('请添加活动商品'),
      discount: [{ required: true, validator: checkDiscount, trigger: ['blur', 'change'] }],
      minDiscount: [{ required: true, validator: checkMinDiscount, trigger: ['blur', 'change'] }]
    }
    return {
      active: 0,
      goodsDiscountParams: {
        id: '',
        tag: '',
        name: '',
        method: 'DISCOUNT',
        level: 'GOOD',
        startTime: '',
        endTime: '',
      },
      oldGoodsDiscountParams: {},
      datetime: [],
      createTime: '',
      rules,
      goodsDiscountLoad: false,
      form: {
        showDialog: false,
        configList: [],
      },
      itemKey: Math.random(),
      goodsListSpecForm: {
        showDialog: false,
        goodsId: '',
        list: [],
      }
    }
  },
  computed: {
    IS_EDIT() {
      return this.goodsDiscountParams.id ? true : false;
    }
  },
  methods: {
    /** SKU优惠设置 dialog关闭 */
    handleDialogClose() {
      this.goodsListSpecForm.showDialog = false;
    },
    /** 添加商品 确定 */
    handleClose(selectList) {
      selectList.forEach((item) => {
        let findItem = this.form.configList.find(({ id, goodsId }) => item.id === id || item.id === goodsId);

        if (!findItem) {
          this.form.configList.push({
            ...item,
            configList: [],
            goodsId: item.id,
            id: '',
            discount: this.goodsDiscountParams.method === 'DISCOUNT' ? '9.9' : '',
            minDiscount: 0,
            maxDiscount: 0,
          })
        }
      })

      this.form.showDialog = false;
    },
    /** SKU优惠设置 确定 */
    handleConfirm() {
      this.$refs['goodsItemForm'].validate(() => {
        let findItem = this.form.configList.find(({ goodsId }) => this.goodsListSpecForm.goodsId === goodsId);
        let findIndex = this.form.configList.findIndex(({ goodsId }) => this.goodsListSpecForm.goodsId === goodsId);

        if (findItem) {
          let configList = this.$moe_lodash.cloneDeepData(this.goodsListSpecForm.list);
          findItem.configList = configList;
          if (this.goodsDiscountParams.method === 'DISCOUNT') {
            findItem.minDiscount = configList.map(({ salePrice, discount }) => this.$moe_math.mathDiv(Math.ceil(this.$moe_math.mathMul(this.$moe_math.mathMul(salePrice, this.$moe_math.mathDiv(discount, 10)), 100)), 100)).sort((a, b) => a - b)[0];
            findItem.maxDiscount = configList.map(({ salePrice, discount }) => this.$moe_math.mathDiv(Math.ceil(this.$moe_math.mathMul(this.$moe_math.mathMul(salePrice, this.$moe_math.mathDiv(discount, 10)), 100)), 100)).sort((a, b) => b - a)[0];
          } else if(this.goodsDiscountParams.method === 'REDUCE') {
            findItem.minDiscount = configList.map(({ salePrice, discount }) => this.$moe_math.mathSub(salePrice, discount)).sort((a, b) => a - b)[0];
            findItem.maxDiscount = configList.map(({ salePrice, discount }) => this.$moe_math.mathSub(salePrice, discount)).sort((a, b) => b - a)[0];
          }
        }

        this.$refs['goodsDiscountGoodsForm'].validateField(`configList.${findIndex}.minDiscount`);

        this.handleDialogClose();
      })
    },
    /** 打折输入框失去焦点 */
    handleBlur(index) {
      if (this.goodsDiscountParams.level === 'GOOD') {
        if (this.$moe_math.mathLessThanOrEqualTo(this.form.configList[index].discount, 0)) {
          this.form.configList[index].discount = '9.9';
        }
      } else if (this.goodsDiscountParams.level === 'SKU') {
        if (this.$moe_math.mathLessThanOrEqualTo(this.goodsListSpecForm.list[index].discount, 0)) {
          this.goodsListSpecForm.list[index].discount = '9.9';
        }
      }
    },
    /** 优惠后价格计算 减免 */
    changeConfigListDiscount(value, index) {
      if (this.goodsDiscountParams.level === 'GOOD') {
        let row = this.form.configList[index];
        row.discount = value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/, '$1$2$3').replace(/^0+(\d)/, '$1').replace(/^\./, '0.').match(/^\d*(\.?\d{0,2})/g)[0] || '';
        if (!value) {
          return false;
        }
        try {
          if (this.$moe_math.mathLessThanOrEqualTo(this.$moe_math.mathSub(row.minPrice, row.discount), 0)) {
            row.discount = this.$moe_math.mathSub(row.minPrice, 0.01);
          }
        } catch {
          console.log('error')
        }
      } else if (this.goodsDiscountParams.level === 'SKU') {
        let row = this.goodsListSpecForm.list[index];
        row.discount = value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/, '$1$2$3').replace(/^0+(\d)/, '$1').replace(/^\./, '0.').match(/^\d*(\.?\d{0,2})/g)[0] || '';
        if (!value) {
          return false;
        }
        try {
          if (this.$moe_math.mathLessThanOrEqualTo(this.$moe_math.mathSub(row.salePrice, row.discount), 0)) {
            row.discount = this.$moe_math.mathSub(row.salePrice, 0.01);
          }
        } catch {
          console.log('error')
        }
      }
    },
    /** 优惠后价格计算 */
    discountComputed(row) {
      if (this.goodsDiscountParams.level === 'GOOD') {
        if (this.goodsDiscountParams.method === 'DISCOUNT') {
          let minPrice = this.$moe_math.mathDiv(Math.ceil(this.$moe_math.mathMul(this.$moe_math.mathMul(row.minPrice, this.$moe_math.mathDiv(row.discount, 10)), 100)), 100);
          let maxPrice = this.$moe_math.mathDiv(Math.ceil(this.$moe_math.mathMul(this.$moe_math.mathMul(row.maxPrice, this.$moe_math.mathDiv(row.discount, 10)), 100)), 100);
          return `¥ ${minPrice} ${this.$moe_math.mathGreaterThan(maxPrice, minPrice) ? `- ¥ ${maxPrice}` : ''}`;
        } else if (this.goodsDiscountParams.method === 'REDUCE') {
          let minPrice = this.$moe_math.mathSub(row.minPrice, row.discount);
          let maxPrice = this.$moe_math.mathSub(row.maxPrice, row.discount);
          return `¥ ${minPrice} ${this.$moe_math.mathGreaterThan(maxPrice, minPrice) ? `- ¥ ${maxPrice}` : ''}`;
        }
      } else if (this.goodsDiscountParams.level === 'SKU') {
        return `¥ ${row.minDiscount} ${this.$moe_math.mathGreaterThan(row.maxDiscount, row.minDiscount) ? `- ¥ ${row.maxDiscount}` : ''}`;
      }
    },
    /** sku优惠后价格计算 */
    skuDiscountComputed(row) {
      if (this.goodsDiscountParams.method === 'DISCOUNT') {
        return `¥ ${this.$moe_math.mathDiv(Math.ceil(this.$moe_math.mathMul(this.$moe_math.mathMul(row.salePrice, this.$moe_math.mathDiv(row.discount, 10)), 100)), 100)}`;
      } else if(this.goodsDiscountParams.method === 'REDUCE') {
        return `¥ ${this.$moe_math.mathSub(row.salePrice, row.discount)}`;
      }
    },
    /** 删除添加的商品 */
    handleDelete({ name }, index) {
      this.$moe_layer.confirm(`您确定要撤出当前选项"${name}”吗？`, () => {
        this.form.configList.splice(index, 1);
      });
    },
    handleStep(active, type) {
      switch (type) {
        case 'updateDiscount':
          this.updateDiscount(active);
          break;
        case 'updateDiscountConfigList':
          this.updateDiscountConfigList(active)
          break;
        default:
          this.active = active;
          break;
      }
    },
    /** 创建折扣活动 */
    addDiscount() {
      this.$refs['goodsDiscountForm'].validate(() => {
        this.goodsDiscountLoad = true;

        this.$moe_api.GOODS_DISCOUNT.addDiscount(this.goodsDiscountParams).then((data) => {
          if (data.code === 200) {
            this.$moe_msg.success('新增单品折扣成功', {
              completionHandler: () => {
                this.$router.replace({
                  query: {
                    id: data.result.id
                  }
                })
                this.getDiscountDetail();
                this.handleStep(1);
              }
            });
          } else {
            this.$moe_msg.error(data.message);
          }
        }).finally(() => {
          this.goodsDiscountLoad = false;
        })
      })
    },
    /** 修改折扣活动 */
    updateDiscount(active) {
      this.$refs['goodsDiscountForm'].validate(() => {
        let { id, tag, name, method, level, startTime, endTime } = this.oldGoodsDiscountParams;
        if (this.goodsDiscountParams.id !== id
          || this.goodsDiscountParams.tag !== tag
          || this.goodsDiscountParams.name !== name
          || this.goodsDiscountParams.method !== method
          || this.goodsDiscountParams.level !== level
          || this.goodsDiscountParams.startTime !== startTime
          || this.goodsDiscountParams.endTime !== endTime) {
          this.$msgbox({
            title: '系统提示',
            message: `检测到您修改了活动信息,您确定要保存并继续吗？`,
            showCancelButton: true,
            confirmButtonText: '保存并继续',
            cancelButtonText: '取消并继续',
          }).then(() => {
            this.goodsDiscountLoad = true;
            this.$moe_api.GOODS_DISCOUNT.updateDiscount(this.goodsDiscountParams).then((data) => {
              if (data.code === 200) {
                this.oldGoodsDiscountParams = this.$moe_lodash.cloneDeepData(this.goodsDiscountParams);
                this.$moe_msg.success('修改活动信息成功', {
                  completionHandler: () => {
                    this.active = active;
                  }
                });
              } else {
                this.$moe_msg.error(data.message);
              }
            }).finally(() => {
              this.goodsDiscountLoad = false;
            });
          }).catch(() => {
            this.goodsDiscountParams = this.$moe_lodash.cloneDeepData(this.oldGoodsDiscountParams);
            this.active = active;
          })
        } else {
          this.active = active;
        }
      })
    },
    /** 修改折扣活动商品配置信息 */
    updateDiscountConfigList(active) {
      this.$refs['goodsDiscountGoodsForm'].validate(() => {
        this.goodsDiscountLoad = true;
        let configList = [];
        if (this.goodsDiscountParams.level === 'GOOD') {
          configList = this.form.configList.map(({ goodsId, goodsItemId, id, discount }) => {
            return {
              goodsId,
              goodsItemId: goodsItemId || '',  // 商品skuId(sku级别时必传)
              id: id || '',
              discount: discount || 0,         // 折扣
            }
          })
        } else if (this.goodsDiscountParams.level === 'SKU') {
          configList = this.form.configList.map(({ configList }) => {
            return configList.map(({ goodsId, goodsItemId, id, discount }) => {
              return {
                goodsId,
                goodsItemId: goodsItemId || '', // 商品skuId(sku级别时必传)
                id: id || '',
                discount: discount || 0,        // 折扣
              }
            })
          }).flat()
        }

        this.$moe_api.GOODS_DISCOUNT.updateDiscountConfigList({
          configList,
          id: this.goodsDiscountParams.id,
          publish: true,
        }).then((data) => {
          if (data.code === 200) {
            this.active = active;
          } else {
            this.$moe_msg.error(data.message);
          }
        }).finally(() => {
          this.goodsDiscountLoad = false;
        });
      })
    },
    /** 查询商品详情 */
    queryShopGoodsById(row, index) {
      this.$moe_api.GOODS_API.queryShopGoodsById({ id: row.goodsId }).then((data) => {
        if (data.code === 200) {
          this.goodsListSpecForm.goodsId = data.result.id;
          let goodsItem = this.$moe_lodash.cloneDeepData(data.result.goodsItem);
          if (this.form.configList[index].configList.length) {
            this.goodsListSpecForm.list = goodsItem.filter(({ delFlag }) => delFlag === 0).map((item) => {
              item.goodsItemId = item.id;
              let findItem = this.form.configList[index].configList.find(({ goodsItemId }) => item.goodsItemId === goodsItemId);
              return {
                ...item,
                discount: findItem.discount || '0',
                id: findItem.id || ''
              }
            })
          } else {
            this.goodsListSpecForm.list = goodsItem.filter(({ delFlag }) => delFlag === 0).map((item) => {
              return {
                ...item,
                goodsItemId: item.id,
                minPrice: data.result.minPrice,
                maxPrice: data.result.maxPrice,
                id: '',
                discount: this.goodsDiscountParams.method === 'DISCOUNT' ? '10.0' : '',
              }
            });
          }
          this.goodsListSpecForm.showDialog = true;
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 获取折扣活动详情 */
    getDiscountDetail() {
      if (this.$route.query.id) {
        this.$moe_api.GOODS_DISCOUNT.getDiscountDetail({ id: this.$route.query.id }).then((data) => {
          if (data.code === 200) {
            let r = data.result;
            this.goodsDiscountParams = {
              id: r.id,
              tag: r.tag,
              name: r.name,
              method: r.method,
              level: r.level,
              startTime: r.startTime,
              endTime: r.endTime,
            };

            this.createTime = r.createTime;
            this.datetime = [r.startTime, r.endTime];

            this.oldGoodsDiscountParams = this.$moe_lodash.cloneDeepData(this.goodsDiscountParams);

            if (r.configList.length) {
              this.getQueryShopGoodsByIds(r.configList);
            }
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      }
    },
    /** 根据商品ID获取商品列表 */
    getQueryShopGoodsByIds(goodsList) {
      //数组去重
      let unionByList = this.$moe_lodash.unionByList(goodsList, goodsList, 'goodsId')
      this.$moe_api.GOODS_API.getQueryShopGoodsByIds({ ids: unionByList.map(({ goodsId }) => goodsId).join(',') }).then((data) => {
        if (data.code === 200) {
          let result = data.result;
          switch (this.goodsDiscountParams.level) {
            case 'GOOD':
              this.form.configList = unionByList.map((item) => {
                let findGoodsItem = result.find(({ id }) => item.goodsId === id);
                return {
                  ...findGoodsItem,
                  goodsId: findGoodsItem.id,
                  ...item,
                }
              })
              break;
            case 'SKU':
              this.form.configList = unionByList.map((item) => {
                let findGoodsItem = result.find(({ id }) => item.goodsId === id);
                let configList = goodsList.filter(({ goodsId }) => item.goodsId === goodsId).map((goodsListItem) => {
                  return {
                    ...goodsListItem,
                  }
                })

                let minDiscount = 0, maxDiscount = 0;
                if (this.goodsDiscountParams.method === 'DISCOUNT') {
                  minDiscount = configList.map(({ discount }) => this.$moe_math.mathDiv(Math.ceil(this.$moe_math.mathMul(this.$moe_math.mathMul(findGoodsItem.minPrice, this.$moe_math.mathDiv(discount, 10)), 100)), 100)).sort((a, b) => a - b)[0];
                  maxDiscount = configList.map(({ discount }) => this.$moe_math.mathDiv(Math.ceil(this.$moe_math.mathMul(this.$moe_math.mathMul(findGoodsItem.maxPrice, this.$moe_math.mathDiv(discount, 10)), 100)), 100)).sort((a, b) => b - a)[0];
                } else if (this.goodsDiscountParams.method === 'REDUCE') {
                  minDiscount = this.$moe_math.mathSub(findGoodsItem.minPrice, configList.map(({ discount }) => Number(discount)).sort((a, b) => a - b)[0]);
                  maxDiscount = this.$moe_math.mathSub(findGoodsItem.maxPrice, configList.map(({ discount }) => Number(discount)).sort((a, b) => b - a)[0]);
                }

                return {
                  ...findGoodsItem,
                  goodsId: findGoodsItem.id,
                  ...item,
                  configList: configList.length ? configList : [],
                  minDiscount,
                  maxDiscount
                }
              })
              break;
          }
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    }
  },
  mounted() {
    this.getDiscountDetail();
  }
}
</script>

<style lang="scss" scoped>

</style>